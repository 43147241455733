import { z } from 'zod'
import { IMAGE_DEFAULT_MAX_SIZE } from '~/components/input-image/image-input'
import {
	emailSchema,
	nameSchema,
	passwordSchema,
} from '~/utils/user-validation'
import {
	checkboxSchema,
	httpSchema,
	imageOptionalSchema,
	phonenumberSchema,
} from '~/utils/zod-extensions'

export const languageSchema = z.enum(['en', 'sv', 'no', 'da', 'fi'])

export const loginFormSchema = z.object({
	email: emailSchema,
	password: passwordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export const forgotPasswordSchema = z.object({
	email: emailSchema,
	redirectUser: z.boolean().optional().default(false),
	lang: languageSchema,
})

export const signupSchema = z.object({
	email: emailSchema,
	lang: languageSchema,
})

export const resetPasswordSchema = z
	.object({
		password: passwordSchema,
		confirmPassword: passwordSchema,
		lang: languageSchema,
	})
	.refine(({ confirmPassword, password }) => password === confirmPassword, {
		message: 'form_error_password_dont_match',
		path: ['confirmPassword'],
	})

export const onboardingFormSchema = z
	.object({
		name: nameSchema,
		password: passwordSchema,
		confirmPassword: passwordSchema,
		address: z.string().optional(),
		orgnr: z.string().optional(),
		website: httpSchema().optional(),
		description: z.string().optional(),
		phonenumber: phonenumberSchema().optional(),
		country: z.string().optional(),
		agreeToTermsOfServiceAndPrivacyPolicy: checkboxSchema(
			'form_error_accept_policy',
		),
		remember: checkboxSchema(),
		subscribeToNewsletter: checkboxSchema(),
		redirectTo: z.string().optional(),
		file: imageOptionalSchema(IMAGE_DEFAULT_MAX_SIZE).optional(),
	})
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'form_error_password_missmatch',
			})
		}
	})
